<template>
  <div class="collapsable-wrapper">
    <div v-editable="blok" class="collapsable-container">
      <div class="acordion-border-box">
        <div>
          <button
            class="flex items-center space-x-3"
            :aria-expanded="isOpen"
            :aria-controls="`collapse`"
            @click="toggleCollapsable()"
          >
            <div
              v-if="blok.link.cached_url"
              :id="blok.id ? blok.id : undefined"
              class="global-nav-list__link global-nav-list__parent-link"
            >
              <span
                v-if="blok.display_name"
                class="global-nav-list__link-text"
                >{{ blok.display_name }}</span
              >
              <span v-else class="global-nav-list__link-text">{{
                blok.link.story?.name ?? ''
              }}</span>
              <span v-if="blok.sub_links" class="chev-span">
                &nbsp;<svg
                  class="w-2 inline-block nav-chev transition-all duration-200 transform"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 10"
                  aria-hidden="true"
                >
                  <path
                    d="M15 1.2l-7 7-7-7"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <svg
              class="w-3 transition-all duration-200 transform"
              :class="{
                'rotate-180': isOpen,
                'rotate-0': !isOpen,
              }"
              fill="none"
              stroke="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 10"
              aria-hidden="true"
            >
              <path
                d="M15 1.2l-7 7-7-7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div v-show="isOpen" :id="`collapse`" class="collapsable-content">
          <ul v-if="blok.sub_links" class="dropdown-menu">
            <li v-for="sub_link in blok.sub_links" :key="sub_link._uid">
              <NuxtLink
                v-if="sub_link.link.cached_url"
                :id="sub_link.id ? sub_link.id : undefined"
                :to="
                  sub_link.link.linktype === 'url'
                    ? sub_link.link.cached_url
                    : '/' + sub_link.link.cached_url
                "
                :rel="
                  sub_link.link.target === '_blank'
                    ? 'nofollow noopener'
                    : undefined
                "
                :target="
                  sub_link.link.target === '_blank' ? sub_link.link.target : ''
                "
                class="dropdown-menu__link"
              >
                <span
                  v-if="sub_link.display_name"
                  class="dropdown-menu__link"
                  >{{ sub_link.display_name }}</span
                >
                <span v-else class="dropdown-menu__link">{{
                  sub_link.link.story?.name ?? ''
                }}</span>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { GlobalNavLinkProps } from '~/types/GlobalHeader';
defineProps<{
  blok: GlobalNavLinkProps;
}>();

const isOpen = ref(false);

const toggleCollapsable = () => {
  isOpen.value = !isOpen.value;
};
</script>
<style lang="scss">
.collapsable-container {
  padding-bottom: 0.5rem;
}

.opentitle {
  color: #0abc28;
}

.collapsable-content {
  color: #1d2227 !important;
}
</style>
